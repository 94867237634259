/* 
*
* Custom CSS 
*
*/

#editable-cell:hover
{
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    padding: 4px 10px !important;
}

.description-title-span
{
  color: rgba(0, 0, 0, 0.45);
}
.description-title-span::after {
  content: ":";
  position: relative;
  top: -0.5px;
  margin-inline: 2px 8px;
}

.ant-form-vertical .reduced-label-padding .ant-form-item-label
{
  padding: 0 0 1px !important;
}

.quote-title-input {
  margin-top: 0px;
  margin-bottom: 0px;
}

.reduced-label-padding
{
  margin-bottom: 7px;
}

.reduced-label-padding .ant-input-number-affix-wrapper.ant-input-number-affix-wrapper-status-success,
.reduced-label-padding .ant-input-affix-wrapper.ant-input-affix-wrapper-status-success {
  border-color: #0f4c85 !important;
  box-shadow: 0 0 0 2px rgba(2, 42, 58, 0.3);
  outline: 0;
}

li.ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected > div.ant-menu-submenu-title{
background: #ebdeda !important;
}
.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border-inline-end: none !important;
}
.main-header {
  background: #00305b;
  position: relative;
  z-index: 1000;
  padding: 0px 0px;
  height: 65px;
  box-shadow: 0 0px 0px 0 #00305b, 0 0px 0px 0px #00305b, 0 1px 2px 0 #00305b;
  border-bottom: 1px solid #dce0e3 !important;
}

.highlight-pilot-table-row {
  background: #f2f9ff;
}
.highlight-pilot-table-row:hover {
  background: #e6f4ff !important;
}
.highlight-pilot-table-row>.ant-table-cell-row-hover {
  background: #e6f4ff !important;
}

.ant-card-small.checked-coverage >.ant-card-head
{
  min-height: 46px;
}

.main-side-menu {
  border-right: 1px solid #dce0e3 !important;
  background: #ffffff !important;
  height: calc(100vh - 65px);
  padding-top: 20px;
}

.sider-menu-component {
  width: 200px;
  background: #ffffff !important;
  border-right: 1px solid #dce0e3 !important;
}

.ant-layout-sider-trigger {
  /* border-top: 1px solid #dce0e3; */
  border-right: 1px solid #dce0e3;
  background: #f5f5f5 !important;
}

.main-sider-menu-trigger {
  color: black;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s;
}

.air1-logo {
  background-size: 100% 100%;
  margin: 1px 0px 1px 0px;
  height: 62px;
  width: 176px;
  background-image: url("assets/air1-logo.png");
  background-repeat: no-repeat;
}
@media (max-width: 575px) {
  .increased-xs-margin > .ant-row > .ant-form-item-label
  {
    margin-bottom: 30px !important;
  }
}

.body-layout-parent {
  background: #f5f5f5;
  height: calc(100vh - 65px);
  position: relative;
}

.body-header {
  background: #ffffff;
  /* padding: 12px 0px; */
  height: 98px;
  padding-right: 32px;
  padding-left: 32px;
  border-bottom: 1px solid #f0f0f0;
}

.scroll-body-layout {
  overflow-y: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.expanded-height {
  height: calc(100% - 65px);
}

.centered-body-background {
  margin: 16px 16px;
  background: #ffffff;
  min-height: calc(100vh - 190px);
}

.aviation-form-card-content {
  margin: 8px 8px;
  background: #ffffff;
  min-height: calc(100vh - 171px);
}

.form-card-style {
  background: #fafafa;
   margin-bottom: 16px;
}

.body-card-content {
  margin: 16px 16px;
  background: #ffffff;
  min-height: calc(100vh - 171px);
}

.body-card-content-no-min-height {
  margin: 16px 16px;
  background: #ffffff;
}

.default-margin {
  margin: 16px 16px;
}

.no-padding-body .ant-card-body {
  padding: 0px !important;
}

.ant-input-group-addon {
  border-radius: 0 !important;
}

.ant-input {
  border-radius: 0 !important;
}

.link-button-primary {
  color: #0f4c85;
}

.link-button-primary:not([disabled]):hover {
  color: #506980;
}

.ant-notification .ant-notification-notice-wrapper {
  border-radius: 1px;
}

/*
*
* Default CSS (comes with create-react-app) 
*
*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
